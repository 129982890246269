<template>
    <section class="pr-3 pt-4 custom-scroll overflow-auto" style="height:calc(100vh - 136px)">
        <p class="text-general f-600 f-18 bg-white br-t-8 py-2 px-2">
            Seleccionar ente a analizar
        </p>
        <!-- Separador -->
        <!-- <div class="row mx-0 py-3" /> -->
        <!-- Separador -->
        <section class="position-relative">
            <cargando v-if="informeInventarioCargando" />
            <div class="row mx-0 align-items-center bg-white br-b-8 py-2 pb-3 px-2">
                <input v-model="tipo_inventario" :value="1" type="radio" class="option-input black radio" @change="cambioTipo" />
                <div class="col d-middle pl-0 pr-2">
                    <span class="f-15 text-general mr-2">CEDIS</span>
                    <el-select v-model="id_cedis" :disabled="tipo_inventario != 1" class="w-75" filterable clearable @change="cambioSeleccion">
                        <el-option
                        v-for="item in cedisCopy"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <input v-model="tipo_inventario" :value="2" type="radio" class="option-input black radio" @change="cambioTipo" />
                <div class="col d-middle pr-2 pl-0">
                    <span class="f-15 text-general mr-2">{{ $config.vendedor }}</span>
                    <el-select
                    v-model="id_leechero"
                    filterable
                    clearable
                    remote
                    class="w-100"
                    reserve-keyword
                    :remote-method="remoteMethod"
                    :disabled="tipo_inventario != 2"
                    @change="cambioSeleccion"
                    >
                        <el-option
                        v-for="item in leecherosSelect"
                        :key="item.id"
                        :label="`${item.nombre} - ${item.nombre_cedis}`"
                        :value="item.id"
                        />
                    </el-select>
                    <!-- <el-select v-model="id_leechero" :disabled="tipo_inventario != 2" class="w-75" @change="cambioSeleccion">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select> -->
                </div>
                <input v-model="tipo_inventario" :value="3" type="radio" class="option-input black radio ml-3" @change="cambioTipo" />
                <div class="col d-middle pr-2 pl-0">
                    <span class="f-15 text-general mr-2">Equipos {{ $config.vendedor }}</span>
                    <el-select
                    v-model="id_team"
                    filterable
                    clearable
                    remote
                    class="w-100"
                    reserve-keyword
                    :remote-method="remoteMethod"
                    :disabled="tipo_inventario != 3"
                    @change="cambioSeleccion"
                    >
                        <el-option
                        v-for="item in leecherosSelect"
                        :key="item.id"
                        :label="`${item.nombre}`"
                        :value="item.id"
                        />
                    </el-select>
                    <!-- <el-select v-model="id_team" :disabled="tipo_inventario != 3" class="w-75" @change="cambioSeleccion">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select> -->
                </div>
            </div>
            <!-- Separador -->
            <div class="row mx-0 py-3" />
            <!-- Separador -->
            <div class="row mx-0 px-3 bg-white br-12 py-3">
                <div class="col-auto border-right pt-3 text-general f-18 f-600">
                    Inventario de <br /> todos los cedis
                </div>
                <div class="col border-right text-center text-general">
                    <p class="text-general f-30 f-600">{{ agregarSeparadoresNumero(inventario.productos) }}</p>
                    <div class="row justify-center align-items-center mt-2">
                        <i class="icon-package-variant-closed f-20 text-general" />
                        <span class="text-general"> Productos</span>
                    </div>
                </div>
                <div class="col border-right">
                    <p class="text-general f-30 f-600 text-center">
                        {{ agregarSeparadoresNumero(inventario.unidades) }}
                    </p>
                    <div class="text-general row mx-0 justify-center align-items-center mt-2">
                        <i class="icon-format-list-checks f-20 text-general" />
                        Unidades
                    </div>
                </div>
                <div class="col">
                    <p class="text-general f-30 f-600 text-center">
                        {{ separadorNumero(inventario.valores) }}
                    </p>
                    <div class="row mx-0 align-items-center text-general justify-center mt-2">
                        <i class="icon-cash f-20 mr-3" />
                        {{ moneda.sigla }}
                    </div>
                </div>
            </div>
            <!-- Separador -->
            <div v-if="tipo_inventario == 1 && idSeleccion == 0" class="row mx-0 py-3" />
            <!-- Separador -->
            <div v-if="tipo_inventario == 1 && idSeleccion == 0" class="row mx-0">
                <p class="col-12 mb-3 text-general f-18 f-600">
                    Inventario actual en cada uno de los CEDIS
                </p>
                <div class="col-12">
                    <echart :options="cedisInventario" />
                    <div v-if="_.sumBy(cedisInventario.dataset.source,'total') == 0 && _.sumBy(cedisInventario.dataset.source,'cantidad') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                    <div v-if="_.sumBy(cedisInventario.dataset.source,'total') == 0 && _.sumBy(cedisInventario.dataset.source,'cantidad') == 0" class="img-sindatos text-center">
                        <img class="obj-cover" src="/img/otros/no_datos.svg" />
                    </div>
                </div>
            </div>
            <!-- Separador -->
            <div class="row mx-0 py-3" />
            <!-- Separador -->
            <div class="row mx-0 bg-white py-2 br-8">
                <p class="col-12 text-general f-18 f-600 my-2 mb-3">
                    Inventario de los cedis en los últimos 12 meses
                </p>
                <div class="col-12 d-middle-center mb-2">
                    <label for="radio1" class="pr-1 d-flex align-items-end pb-2 col-auto cr-pointer">
                        <input id="radio1" v-model="radio" :value="1" type="radio" class="option-input radio black" />
                        <p class="f-14">Dinero</p>
                    </label>
                    <label for="radio2" class="pr-1 d-flex align-items-end pb-2 col-auto cr-pointer">
                        <input id="radio2" v-model="radio" :value="2" type="radio" class="option-input radio black" />
                        <p class="f-14">Productos</p>
                    </label>
                    <label for="radio3" class="pr-1 d-flex align-items-end pb-2 col-auto cr-pointer">
                        <input id="radio3" v-model="radio" :value="3" type="radio" class="option-input radio black" />
                        <p class="f-14">Unidades</p>
                    </label>
                    <label for="radio4" class="pr-1 d-flex align-items-end pb-2 col-auto cr-pointer">
                        <input id="radio4" v-model="radio" :value="4" type="radio" class="option-input radio black" />
                        <p class="f-14">Valor del producto (dinero/unidades)</p>
                    </label>
                </div>
                <div class="col-12 mt-3">
                    <echart :options="inventarioCedis" />
                </div>
            </div>
            <!-- Separador -->
            <div class="row mx-0 py-3" />
            <!-- Separador -->
            <div class="row mx-0 bg-white br-12 py-3">
                <p class="col-12 text-general f-18 f-600">
                    Compras en los últimos 30 días
                </p>
            </div>
            <div class="d-flex">
                <div class="pt-4" style="width:155px;">
                    <div class="bg-light-f5 br-12 text-general text-center p-1 shadow">
                        <p class="">Valor de las <br /> Compras</p>
                        <p class="f-600 my-2"> {{ separadorNumero(maxDias30.totalCompras) }} </p>
                    </div>

                    <div class="mt-3 bg-light-f5 br-12 text-general text-center p-1 shadow">
                        <p class="">Día de mayor <br /> compras</p>
                        <p class="f-600 my-1">{{ maxDias30.maxCompras.dia }}</p>
                        <p> {{ separadorNumero(maxDias30.maxCompras.total) }} </p>
                    </div>
                </div>
                <div class="col">
                    <echart :options="comprasUlt30Dias" />
                    <div v-if="_.sumBy(comprasUlt30Dias.dataset.source,'total') == 0 && _.sumBy(comprasUlt30Dias.dataset.source,'cantidad') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                    <div v-if="_.sumBy(comprasUlt30Dias.dataset.source,'total') == 0 && _.sumBy(comprasUlt30Dias.dataset.source,'cantidad') == 0" class="img-sindatos text-center">
                        <img class="obj-cover" src="/img/otros/no_datos.svg" />
                    </div>
                </div>
            </div>
            <!-- Separador -->
            <div class="row mx-0 py-3" />
            <!-- Separador -->
            <div class="row mx-0">
                <p class="col-12 text-general f-18 f-600">
                    Compras en los últimos 24 semanas
                </p>
            </div>
            <div class="d-flex">
                <div class="pt-4" style="width:155px;">
                    <div class="bg-light-f5 br-12 text-general text-center p-1 shadow">
                        <p class="">Valor de las <br /> Compras</p>
                        <p class="f-600 my-2">{{ separadorNumero(maxSemanas.totalCompras) }}</p>
                    </div>

                    <div class="mt-3 bg-light-f5 br-12 text-general text-center p-1 shadow">
                        <p class="">Semana con más <br /> compras</p>
                        <p class="f-600 my-1">{{ maxSemanas.maxCompras.semana }}</p>
                        <p>{{ separadorNumero(maxSemanas.maxCompras.total) }}</p>
                    </div>
                </div>
                <div class="col">
                    <echart :options="inventario24Semanas" />
                    <div v-if="_.sumBy(inventario24Semanas.dataset.source,'total') == 0 && _.sumBy(inventario24Semanas.dataset.source,'cantidad') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                    <div v-if="_.sumBy(inventario24Semanas.dataset.source,'total') == 0 && _.sumBy(inventario24Semanas.dataset.source,'cantidad') == 0" class="img-sindatos text-center">
                        <img class="obj-cover" src="/img/otros/no_datos.svg" />
                    </div>
                </div>
            </div>
            <!-- Separador -->
            <div class="row mx-0 py-3" />
            <!-- Separador -->
            <div class="row mx-0 mb-3">
                <div class="col-auto text-general f-600 f-18">
                    Inventario por valor de categorías y numero de productos
                </div>
                <div class="col-auto ml-auto">
                    <div class="bg-general3 text-white br-20 px-3 cr-pointer" @click="verCategorias()">
                        Ver más categorías
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div v-for="(data,d) in categoriasInventario" :key="d" class="tres-puntos mr-2 mb-2" style="width:117px;">
                    <div class="br-10 border d-middle-center" style="width:117px;height:81px;background-color: #F8F9FF;">
                        <p class="text-general2 text-center nombre-categoria">{{ data.nombre }}</p>
                    </div>
                    <p class="text-general tres-puntos f-15 my-2 text-center">
                        {{ separadorNumero(data.total) }}
                    </p>
                    <p class="text-general tres-puntos f-16 text-general f-500 text-center">
                        {{ agregarSeparadoresNumero(data.cantidad) }}
                    </p>
                </div>
            </div>
            <!-- Separador -->
            <div class="row mx-0 py-3" />
            <!-- Separador -->
            <div class="row mx-0">
                <p class="col-12 mb-3 text-general f-18 f-600">
                    Inventario de los productos comprados en las ultimas 12 semanas
                </p>
                <div class="col-12">
                    <echart ref="inventario12Semanas" :options="inventario12Semanas" />
                    <div v-if="_.sumBy(inventario12Semanas.dataset.source,'total') == 0 && _.sumBy(inventario12Semanas.dataset.source,'cantidad') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                    <div v-if="_.sumBy(inventario12Semanas.dataset.source,'total') == 0 && _.sumBy(inventario12Semanas.dataset.source,'cantidad') == 0" class="img-sindatos text-center">
                        <img class="obj-cover" src="/img/otros/no_datos.svg" />
                    </div>
                </div>
            </div>
            <!-- Separador -->
            <div class="row mx-0 py-3" />
            <!-- Separador -->
            <div class="row mx-0 mb-3">
                <div class="col-auto text-general f-600 f-18">
                    Productos con más inventario ordenado por dinero
                </div>
                <div class="col-auto ml-auto">
                    <div class="bg-general3 text-white br-20 px-3 cr-pointer" @click="productosInventario(productosDineroInventario)">
                        Ver más productos
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-auto">
                    <div style="height:120px;width:40px;" />
                    <div class="row mx-0 justify-center">
                        <el-tooltip placement="bottom" content="Valor del inventario" effect="light">
                            <i class="icon-comision text-general f-18" />
                        </el-tooltip>
                    </div>
                    <div class="row mx-0 justify-center">
                        <el-tooltip placement="bottom" content="Valor del inventario" effect="light">
                            <i class="icon-format-list-checks text-general f-22" />
                        </el-tooltip>
                    </div>
                    <div class="row mx-0 justify-center">
                        <el-tooltip placement="bottom" content="Frecuencia de compra" effect="light">
                            <i class="icon-calendar-sync-outline text-general f-20" />
                        </el-tooltip>
                    </div>
                    <div class="row mx-0 justify-center">
                        <el-tooltip placement="bottom" content="Valor promedio de compra" effect="light">
                            <i class="icon-coin text-general f-20" />
                        </el-tooltip>
                    </div>
                </div>
                <div class="col">
                    <div class="row mx-0">
                        <div v-for="(data, d) in productosDinero" :key="d" class="" style="width:126px;">
                            <el-tooltip placement="bottom" effect="light" :content="data.nombre">
                                <img :src="data.foto" width="115" height="115" class="obj-cover border br-12" />
                            </el-tooltip>
                            <p class="text-general f-15 text-center f-600 my-2">{{ separadorNumero(data.total) }}</p>
                            <p class="text-general f-15 text-center my-2 border-bottom">{{ agregarSeparadoresNumero(data.cantidad_restante) }}</p>
                            <p class="text-general f-15 text-center my-2">{{ agregarSeparadoresNumero(data.frecuencia,1) }}</p>
                            <p class="text-general f-15 text-center my-2">{{ separadorNumero(data.promedio_total_comprado) }} </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Separador -->
            <div class="row mx-0 py-3" />
            <!-- Separador -->
            <div class="row mx-0 mb-3">
                <div class="col-auto text-general f-600 f-18">
                    Productos con más inventario ordenado por cantidad
                </div>
                <div class="col-auto ml-auto">
                    <div class="bg-general3 text-white br-20 px-3 cr-pointer" @click="productosInventario(productosCantidadInventario)">
                        Ver más productos
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-auto">
                    <div style="height:120px;width:40px;" />
                    <div class="row mx-0 justify-center">
                        <el-tooltip placement="bottom" content="Valor del inventario" effect="light">
                            <i class="icon-format-list-checks text-general f-22" />
                        </el-tooltip>
                    </div>
                    <div class="row mx-0 justify-center">
                        <el-tooltip placement="bottom" content="Unidades del inventario" effect="light">
                            <i class="icon-comision text-general f-18" />
                        </el-tooltip>
                    </div>
                    <div class="row mx-0 justify-center">
                        <el-tooltip placement="bottom" content="Frecuencia de compra(en días)" effect="light">
                            <i class="icon-calendar-sync-outline text-general f-20" />
                        </el-tooltip>
                    </div>
                    <div class="row mx-0 justify-center">
                        <el-tooltip placement="bottom" content="Valor promedio de compra" effect="light">
                            <i class="icon-coin text-general f-20" />
                        </el-tooltip>
                    </div>
                </div>
                <div class="col">
                    <div class="row mx-0">
                        <div v-for="(data, d) in productosCantidad" :key="d" class="" style="width:126px;">
                            <el-tooltip placement="bottom" effect="light" :content="data.nombre">
                                <img :src="data.foto" width="115" height="115" class="obj-cover border br-12" />
                            </el-tooltip>
                            <p class="text-general f-15 text-center f-600 my-2">{{ agregarSeparadoresNumero(data.cantidad_restante) }}</p>
                            <p class="text-general f-15 text-center my-2 border-bottom">{{ separadorNumero(data.total) }}</p>
                            <p class="text-general f-15 text-center my-2">{{ agregarSeparadoresNumero(data.frecuencia,1) }}</p>
                            <p class="text-general f-15 text-center my-2">{{ separadorNumero(data.promedio_total_comprado) }} </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Separador -->
        <div class="row mx-0 py-4" />
        <!-- Separador -->
        <div class="row mx-0 align-items-center">
            <div class="col-auto d-middle text-general f-15">
                Rango de análisis
            </div>
            <div class="col-auto" style="width:174px;">
                <el-select v-model="rango" placeholder="Seleccionar" size="medium">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto px-0 mx-2" style="width:248px;">
                <div class="date-picker-edit">
                    <el-date-picker
                    v-model="fecha"
                    class="w-100"
                    size="medium"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    :disabled="disable_fecha"
                    popper-class="date-picker-edit-popper"
                    type="daterange"
                    unlink-panels
                    start-placeholder="Fecha inicio"
                    end-placeholder="Fecha fin"
                    />
                </div>
            </div>
            <div class="col-auto px-0 mr-4">
                <div class="bg-general text-white px-3 py-1 br-10" @click="informe">
                    Mostrar Informe
                </div>
            </div>
            <cargando v-if="cargandoInforme" mini />
        </div>
        <!-- Separador -->
        <div class="row mx-0 py-4" />
        <!-- Separador -->
        <div class="row mx-0 mb-3">
            <div class="col-auto text-general f-600 f-18">
                Productos con mayor frecuencia de compra
            </div>
            <div class="col-auto ml-auto">
                <div class="bg-general3 text-white br-20 px-3 cr-pointer" @click="productosInventario(productosFrecuenciaInventarioFechas, true)">
                    Ver más productos
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-auto">
                <div style="height:120px;width:40px;" />
                <div class="row mx-0 justify-center">
                    <el-tooltip placement="bottom" content="Valor del inventario" effect="light">
                        <i class="icon-comision text-general f-18" />
                    </el-tooltip>
                </div>
                <div class="row mx-0 justify-center">
                    <el-tooltip placement="bottom" content="Valor del inventario" effect="light">
                        <i class="icon-format-list-checks text-general f-22" />
                    </el-tooltip>
                </div>
                <div class="row mx-0 justify-center">
                    <el-tooltip placement="bottom" content="Frecuencia de compra" effect="light">
                        <i class="icon-calendar-sync-outline text-general f-20" />
                    </el-tooltip>
                </div>
                <div class="row mx-0 justify-center">
                    <el-tooltip placement="bottom" content="Valor promedio de compra" effect="light">
                        <i class="icon-coin text-general f-20" />
                    </el-tooltip>
                </div>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data, d) in productosFrecuenciaFechas" :key="d" class="" style="width:126px;">
                        <el-tooltip placement="bottom" effect="light" :content="data.nombre">
                            <img :src="data.foto" width="115" height="115" class="obj-cover border br-12" />
                        </el-tooltip>
                        <p class="text-general f-15 text-center f-600 my-2">{{ agregarSeparadoresNumero(data.cantidades) }}</p>
                        <p class="text-general f-15 text-center my-2 border-bottom">{{ separadorNumero(data.total_comprado) }}</p>
                        <p class="text-general f-15 text-center my-2">{{ agregarSeparadoresNumero(data.frecuencia,1) }}</p>
                        <p class="text-general f-15 text-center my-2">{{ separadorNumero(data.promedio_total_comprado) }} </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Separador -->
        <div class="row mx-0 py-3" />
        <!-- Separador -->
        <div class="row mx-0 mb-3">
            <div class="col-auto text-general f-600 f-18">
                Productos con mayor valor adquirido
            </div>
            <div class="col-auto ml-auto">
                <div class="bg-general3 text-white br-20 px-3 cr-pointer" @click="productosInventario(productosDineroCompradoInventarioFechas, true)">
                    Ver más productos
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-auto">
                <div style="height:120px;width:40px;" />
                <div class="row mx-0 justify-center">
                    <el-tooltip placement="bottom" content="Valor del inventario" effect="light">
                        <i class="icon-format-list-checks text-general f-22" />
                    </el-tooltip>
                </div>
                <div class="row mx-0 justify-center">
                    <el-tooltip placement="bottom" content="Unidades del inventario" effect="light">
                        <i class="icon-comision text-general f-18" />
                    </el-tooltip>
                </div>
                <div class="row mx-0 justify-center">
                    <el-tooltip placement="bottom" content="Frecuencia de compra(en días)" effect="light">
                        <i class="icon-calendar-sync-outline text-general f-20" />
                    </el-tooltip>
                </div>
                <div class="row mx-0 justify-center">
                    <el-tooltip placement="bottom" content="Valor promedio de compra" effect="light">
                        <i class="icon-coin text-general f-20" />
                    </el-tooltip>
                </div>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(data, d) in productosDineroCompradoFechas" :key="d" class="" style="width:126px;">
                        <el-tooltip placement="bottom" effect="light" :content="data.nombre">
                            <img :src="data.foto" width="115" height="115" class="obj-cover border br-12" />
                        </el-tooltip>
                        <p class="text-general f-15 text-center my-2 f-600">{{ separadorNumero(data.total_comprado) }}</p>
                        <p class="text-general f-15 text-center border-bottom my-2">{{ agregarSeparadoresNumero(data.cantidades) }}</p>
                        <p class="text-general f-15 text-center my-2">{{ agregarSeparadoresNumero(data.frecuencia,1) }}</p>
                        <p class="text-general f-15 text-center my-2">{{ separadorNumero(data.promedio_total_comprado) }} </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Separador -->
        <div class="row mx-0 py-3" />
        <!-- Separador -->
        <div class="row mx-0 mb-3">
            <div class="col-auto text-general f-600 f-18">
                Personas que más compras realizaron
            </div>
        </div>
        <div class="row mx-0">
            <div v-for="(data, d) in personas" :key="d" class="" style="width:126px;">
                <el-tooltip placement="bottom" effect="light" :content="data.usuario.nombre">
                    <img :src="data.usuario.foto" width="115" height="115" class="obj-cover border br-12" />
                </el-tooltip>
                <p class="text-general f-15 text-center f-600 my-2">{{ agregarSeparadoresNumero(data.productos) }}</p>
                <p class="text-general f-15 text-center my-2">{{ separadorNumero(data.total) }}</p>
                <p v-if="tipo_inventario != 1" class="text-general f-15 text-center my-2"><i>{{ data.usuario.cedis || 'Sin cedis' }}</i></p>
            </div>
        </div>
        <!-- Partials -->
        <modal-productos-inventario ref="modalProductosInventario" />
        <modal-ver-categorias ref="modalVerCategorias" />
        <modal-productos-stock ref="modalProductosStock" />
    </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import echarts from 'echarts'
Vue.component('echarts',echarts);

/* Colores Graficas */
let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement).getPropertyValue('--color-db')

import Inventario from "~/services/inventarios_estadisticas";
import Accesos from "~/services/accesos_estadisticas"
import moment from 'moment';
import kpi from "~/services/estadisticas/kpi";

const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++){
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export default {
    components: {
        modalProductosInventario: () => import('./partials/modalProductosInventario'),
        modalVerCategorias: () => import('./partials/modalVerCategorias'),
        modalProductosStock: () => import('./partials/modalProductosStock')
    },
    data(){
        let self = this
        return{
            radio:1,
            leecherosSelect: [],
            tipo_inventario: 1,
            id_cedis: null,
            id_leechero: null,
            id_team: null,
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            informeInventarioCargando: false,
            cargandoInforme: false,
            url_image: 'https://www.las2orillas.co/wp-content/uploads/2021/04/biscolata-600x600.jpeg',
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: '',
            value1: '',
            comprasUlt30Dias: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.dia}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[0].data.total)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[1].data.cantidad)} ${obj[1].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ["Valor de inventario", "Productos",]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['dia', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Valor de inventario',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Productos',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]
            },
            inventario24Semanas: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.semana}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[0].data.total)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[1].data.cantidad)} ${obj[1].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ["Valor de inventario", "Productos",]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['semana', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Valor de inventario',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Productos',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]
            },
            inventario12Semanas: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.semana}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[0].data.total)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[1].data.cantidad)} ${obj[1].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ["Valor de inventario", "Productos",]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['semana', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Valor de inventario',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Productos',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]
            },
            cedisInventario: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.nombre}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[0].data.total)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[1].data.cantidad)} ${obj[1].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ["Valor de inventario", "Productos",]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['nombre', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Valor de inventario',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Productos',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]
            },
            maxDias30: {
                maxCompras: {
                    semana: null,
                    dia: null,
                    cantidad: null,
                    total: null,
                },
                totalCompras:0
            },
            maxSemanas: {
                maxCompras: {
                    semana: null,
                    dia: null,
                    cantidad: null,
                    total: null,
                },
                totalCompras:0
            },
            categoriasInventario: [],
            categoriasInventarioOriginal: [],
            productosDineroInventario: [],
            productosCantidadInventario: [],
            productosDinero: [],
            productosCantidad: [],
            productosFrecuenciaInventarioFechas: [],
            productosDineroCompradoInventarioFechas: [],
            productosFrecuenciaFechas: [],
            productosDineroCompradoFechas: [],
            personas: [],
            inventario: {
                productos: 0,
                unidades: 0,
                valores: 0,
            },
            inventarioData: {},
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            moneda: "cedis/moneda",
            cedisNavbar:'cedis/cedis',
        }),
        cedisCopy(){
            return this.cedisNavbar.filter(el => el.id != 0)
        },
        cargandoTerminado(){
            return  !this.informeInventarioCargando &&
                    !this.cargandoInforme
        },
        idSeleccion(){
            let id = 0

            switch (this.tipo_inventario){
            case 1:
                id = this.id_cedis || 0
                break;
            case 2:
                id = this.id_leechero || 0
                break;
            case 3:
                id = this.id_team || 0
                break;
            default:
                id = 0
                break;
            }

            return id
        },
        nombreSeleccion(){
            let nombre = 'Todos'
            if (this.idSeleccion == 0){
                return nombre
            }
            switch (this.tipo_inventario){
            case 1:
                let cedi = this.cedisCopy.find(el => el.id == this.idSeleccion)
                nombre = cedi ? cedi.nombre : 'No registra'
                break;
            case 2:
            case 3:
                let leechero = this.leecherosSelect.find(el => el.id == this.idSeleccion)

                nombre = leechero ? leechero.nombre : 'No registra'
                break;
            default:
                nombre = 'No registra'
                break;
            }

            return nombre
        },
        radioSelected(){
            if(this.radio == 1) return 'dinero'
            if(this.radio == 2) return 'productos'
            if(this.radio == 3) return 'unidades'
            if(this.radio == 4) return 'dineuni'
            return 'dinero'
        },
        inventarioCedis(){
            let datos = this.inventarioData.data
            let ultimo = this.inventarioData.ultimo

            if(_.isEmpty(datos) || !datos.length) return {}

            let meses = _.chain(datos)
                .map(el => el.mesF)
                .uniq()
                .value()

            let cedis = _.chain(datos)
                .map(el => el.cedi)
                .uniq()
                .value()

            let cedisObj = Object.values(cedis).reduce((acc, el) => ({...acc, [el]: [] }), {})

            let cedisWithData = datos.reduce((acc, el) => {
                acc[el.cedi].push(el[this.radioSelected])
                return acc
            }, cedisObj)


            let series = Object.keys(cedisWithData).map((el, key) => {
                return {
                    name: el,
                    type: "bar",
                    stack: "cedis",
                    barWidth: 18,
                    itemStyle: {
                        normal: {
                            color: getRandomColor(),
                        },
                    },
                    data: cedisWithData[el],
                }
            })

            let variacion = []

            let unCedis = cedis[0]

            for (let i = 0; i < cedisWithData[unCedis].length; i++){
                let suma = 0
                for (const cedis in cedisWithData){
                    suma += cedisWithData[cedis][i]
                }
                variacion.push(suma)

            }

            let nueva = []

            for (let i = 0; i < cedisWithData[unCedis].length; i++){

                if(i == 0 ){
                    nueva[i] = Number(ultimo[this.radioSelected]) > 0 ? Math.round((variacion[i] - Number(ultimo[this.radioSelected])) / Number(ultimo[this.radioSelected])) : 100
                    continue
                }

                nueva[i] = variacion[i - 1] > 0 ? Math.round(((variacion[i] - variacion[i - 1]) / variacion[i - 1]) * 100) : 100
            }

            series.push({
                name: "Variacion",
                type: "line",
                yAxisIndex: 1,
                symbol: "circle",
                symbolSize: 6,
                itemStyle: {
                    color: colorGeneral,
                    borderColor: colorGeneral,
                    width: 2,
                    shadowColor: colorGeneral,
                    shadowBlur: 2
                },
                lineStyle: {
                    color: colorGeneral,
                    width: 2,
                    shadowBlur: 2
                },
                data: nueva
            })

            return {

                tooltip: {
                    trigger: "axis",
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    formatter: (params) => {
                        let last = _.last(params)
                        let lastValor = this.agregarSeparadoresNumero(last.value || 100)

                        params.pop()

                        let total = params.reduce((acc, el) => acc += el.value, 0)

                        // console.log({params});
                        let cedis = params.map(el => {
                            let porcentaje = ((el.value * 100) / total) || 0
                            let valor = [1, 4].includes(this.radio) ? this.separadorNumero(el.value) : this.agregarSeparadoresNumero(el.value)

                            return `
                            <p>
                                <span class="icon-blank" style="color:${el.color}" />
                                <span class="text-general2 f-14"> ${el.seriesName} </span>
                                <span class="text-general2 ml-3 f-14">${valor} </span>
                                <span class="text-general2 f-14 ml-3"> ${Math.round(porcentaje)} % </span>
                            </p>
                            `
                        }).join('')

                        return `
                        <span class="text-general f-600">${last.name} </span></br>
                        <i class="icon-brightness f-18 text-general" />
                        <span class="text-general2 f-14"> Variacion ${lastValor}%<span> </br>
                        <span class="text-general2 f-14 pl-4"> Cedis </span> </br>
                        ${cedis}

                        `;
                    }
                },
                grid: {
                    bottom: '5%'
                },
                legend: {
                    data: cedis,
                    top: '4%',
                    textStyle: {
                        color: '#707070',
                        fontSize: 14
                    }
                },
                xAxis: {
                    data: meses,
                    axisLine: {
                        show: true, //隐藏X轴轴线
                        lineStyle: {
                            color: '#3d5269',
                            width: 1
                        }
                    },
                    axisTick: {
                        show: false, //隐藏X轴刻度
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false,
                        textStyle: {
                            color: '#396A87', //X轴文字颜色
                            fontSize: 14
                        },
                        interval: 0,
                        rotate: 30
                    }
                },
                yAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: '#F0F0F0'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: '#F0F0F0'
                            }
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#396A87',
                                fontSize: 14
                            }
                        }
                    },
                    {
                        type: 'value',
                        position: 'right',
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: '#F0F0F0'
                            }
                        },
                        axisLabel: {
                            show: true,
                            formatter: '{value} %', //右侧Y轴文字显示
                            textStyle: {
                                color: '#396A87',
                                fontSize: 14
                            }
                        }
                    }
                ],
                series: series
            }
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            this.cargando()
            this.montar()
        },
    },
    mounted(){
        this.$refs.inventario12Semanas.instance.on('click', (params) => {
            this.productosStock(params);
        })
        this.montar()
    },
    methods: {
        cargando(informes = true){
            if (informes){
                this.informeInventarioCargando = true

            }
            this.cargandoInforme = true
            this.graficasInformeCedis = true
        },
        async montar(){
            try {
                if(this.id_moneda === null){
                    setTimeout(async() => {
                        await this.informeInventario()
                        await this.informeFechas()
                        await this.inventario2()
                        // await this.informeRetirosLeechero()
                    }, 1000);
                }else{
                    await this.informeInventario()
                    await this.informeFechas()
                    await this.inventario2()
                    // await this.informeRetirosLeechero()
                }
            } catch (error){
                console.log(error)
            }
        },
        async remoteMethod(query){
            try {
                let params = {
                    id_cedis: 0,
                    tipo:this.tipo_inventario == 2 ? 1 : 3,
                    query,
                }
                const { data } = await Accesos.listaLeecheros(params)
                this.leecherosSelect = data
            } catch (e){
                this.leecherosSelect = [];
            }
        },
        productosInventario(data, tipo = false){
            let fecha = ''
            if (tipo){
                let fecha_inicio =  moment(this.fecha[0]).format('Y-MM-DD')
                let fecha_fin =  moment(this.fecha[1]).format('Y-MM-DD')
                fecha = `${this.formatearFecha(fecha_inicio,'D MMM YYYY')} - ${this.formatearFecha(fecha_fin,'D MMM YYYY')}`
            }
            this.$refs.modalProductosInventario.toggle(data, fecha);
        },
        verCategorias(){
            this.$refs.modalVerCategorias.toggle(this.categoriasInventarioOriginal);
        },
        productosStock({dataIndex , value}){
            try {
                let params = {
                    fecha_inicio: moment(value.rango[0]).format('Y-MM-DD'),
                    fecha_fin: moment(value.rango[1]).format('Y-MM-DD'),
                    id_seleccion: this.idSeleccion,
                    id_moneda: this.id_moneda,
                    tipo: this.tipo_inventario,
                    semana: dataIndex == 0 ? 1 : 0,
                }
                this.$refs.modalProductosStock.toggle({obj: value, tipoInventario: this.tipo_inventario, nombre: this.nombreSeleccion, params});

            } catch (error){
                console.log(error);
            }

        },
        async cambioTipo(){
            if (this.tipo_inventario != 1){
                await this.remoteMethod('')
            }
            this.id_cedis = null
            this.id_leechero = null
            this.id_team = null
            this.cargando()
            this.montar()
        },
        cambioSeleccion(){
            this.cargando()
            this.montar()
        },
        async informeInventario(){
            try {
                let params = {
                    id_seleccion: this.idSeleccion,
                    id_moneda: this.id_moneda,
                    tipo: this.tipo_inventario,
                }
                this.informeInventarioCargando = true

                const { data } = await Inventario.informeInventario(params)

                this.maxDias30 = data.maxDias30
                this.maxSemanas = data.maxSemanas
                this.inventario = data.inventario
                this.categoriasInventarioOriginal = data.categoriasInventario
                this.productosDineroInventario = data.productosDineroInventario
                this.productosCantidadInventario = data.productosCantidadInventario
                this.productosDinero = data.productosDinero
                this.productosCantidad = data.productosCantidad
                this.categoriasInventario = this.categoriasInventarioOriginal.slice(0,10)

                this.cedisInventario.dataset.source = data.cedisInventario
                this.inventario24Semanas.dataset.source = data.semanas
                this.inventario12Semanas.dataset.source = data.semanas12
                this.comprasUlt30Dias.dataset.source = data.dias30
            } catch (error){
                console.log(error)
            } finally {
                this.informeInventarioCargando = false
            }
        },
        async informeFechas(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_seleccion: this.idSeleccion,
                    id_moneda: this.id_moneda,
                    tipo: this.tipo_inventario,
                }
                this.cargandoInforme = true

                const { data } = await Inventario.informeFechas(params)

                this.productosFrecuenciaInventarioFechas = data.productosFrecuenciaInventarioFechas
                this.productosDineroCompradoInventarioFechas = data.productosDineroCompradoInventarioFechas
                this.productosFrecuenciaFechas = data.productosFrecuenciaFechas
                this.productosDineroCompradoFechas = data.productosDineroCompradoFechas
                this.personas = data.personas

            } catch (error){
                console.log(error)
            } finally {
                this.cargandoInforme = false
            }
        },
        async informe(){
            try {
                this.cargando(false)
                await this.informeFechas()
                // await this.informeRetirosLeechero()
            } catch (error){
                console.log(error)
            }
        },
        async inventario2(){
            try {
                const { data } = await kpi.inventario2();

                this.inventarioData = data

            } catch (error){
                this.error_catch(error);
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.img-sindatos{
        position: absolute;
        z-index: 3;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0%;
        left: 0px;
        width: 100%;
        height: 100%;
        img{
            // border-radius: 50%;
            /* max-height: 100%; */
            box-shadow: 0px 3px 6px #00000029;
            max-width: 50%;
            height: 50%;
        }
        .mensaje{
            color: #fff;
            text-shadow: 0 2px 3px #000000;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

.nombre-categoria{
    height: 52px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 17px;
}
</style>
