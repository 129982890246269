import axios from 'axios'

const API_PREFIX = "inventarios-estadisticas";

const ENDPOINTS = {
    informeInventario: params => axios(`${API_PREFIX}/informe`, { params }),
    verStockSemana: params => axios(`${API_PREFIX}/ver-stock-semana`, { params }),
    informeFechas: params => axios(`${API_PREFIX}/informe-fechas`, { params })
};

export default ENDPOINTS
